<template>
  <div id="index" ref="appRef">
    <div class="main-container bg">
      <iframe :src="iframeSrc"></iframe>
    </div>
  </div>
</template>

<script>
import drawMixin from '@/utils/drawMixin'


export default {
  mixins: [drawMixin],
  name: 'Farm',
  data() {
    return {
      iframeSrc: `http://${window.location.host}/#/largeScreen`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/index.scss';

.main-container {
  iframe {
    width: 1920px;
    height: 1080px;
    border: none;
  }
}

</style>
